<template>
  <div class="landing-page" :style="landingStyle">
    <Preloader :value="!unionLoaded" fullscreen />
    <v-content class="landing" v-show="unionLoaded">
      <v-container class="header">
        <v-row>
          <v-col cols="12" class="px-0 py-0 d-flex justify-center">
            <Logo :src="unionData.logo" :defaultHeight="isMobile ? 50 : 80" />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="px-0 py-0">
        <v-row>
          <v-col cols="12" class="d-flex justify-center flex-column heading-block">
            <div class="heading-block__text">
              <div
                class="heading-block__text-heading"
                v-if="unionData.text.header.heading"
                v-text="unionData.text.header.heading"
              ></div>
              <div
                class="heading-block__text-subheading"
                v-if="unionData.text.header.subheading"
                v-text="unionData.text.header.subheading"
              ></div>
            </div>
            <div class="heading-block__button-wrapper">
              <v-btn @click="handleButtonClick" :ripple="false">
                FILE NOW
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="video-block">
        <v-row>
          <v-col cols="12" class="py-0 d-flex justify-center">
            <!-- <div class="video-block__dummy">
              <img
                :src="
                  this.union.form === 'USASA'
                    ? '/img/video-usasa-dummy.png'
                    : '/img/video-dummy.png'
                "
                alt="video-dummy"
              />
              <img
                class="video-block__button"
                v-if="this.union.form === 'USASA'"
                src="@/assets/img/icons/play--bright.svg"
                alt="Play"
              />
              <img
                class="video-block__button"
                v-else
                src="@/assets/img/icons/play.svg"
                alt="Play"
              />
            </div> -->
            <VideoPlayer
              :color="unionData.primaryColor"
              class="video-block__player"
              :src="unionData.videoSrc"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="steps-title-block">
        <v-row>
          <v-col md="10" sm="12" offset-md="2" offset-sm="0" class="px-0 py-0">
            <div class="steps-block__heading">
              <span class="steps-block__heading-highlighted">
                {{
                  union && union.form === 'USASA'
                    ? 'File a claim as easy as'
                    : '3 steps to file a claim'
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="steps-content-block py-0">
        <v-row>
          <v-col
            md="4"
            sm="12"
            class="px-0 py-0 steps-content-block__item steps-content-block__item--1"
          >
            <div class="steps-content-block__item-text">
              <div class="steps-content-block__item-text-number-wrapper">
                <div class="steps-content-block__item-text-number">
                  1
                </div>
              </div>
              <div class="steps-content-block__item-text-wrapper">
                <div
                  class="steps-content-block__item-text-title"
                  v-if="unionData.text.steps[1].heading"
                  v-text="unionData.text.steps[1].heading"
                ></div>
                <div
                  class="steps-content-block__item-text-info"
                  v-if="unionData.text.steps[1].content"
                  v-text="unionData.text.steps[1].content"
                ></div>
              </div>
            </div>
          </v-col>
          <v-col
            md="4"
            sm="12"
            class="px-0 py-0 steps-content-block__item steps-content-block__item--2"
          >
            <div class="steps-content-block__item-text">
              <div class="steps-content-block__item-text-number-wrapper">
                <div class="steps-content-block__item-text-number">
                  2
                </div>
              </div>
              <div class="steps-content-block__item-text-wrapper">
                <div
                  class="steps-content-block__item-text-title"
                  v-if="unionData.text.steps[2].heading"
                  v-text="unionData.text.steps[2].heading"
                ></div>
                <div
                  class="steps-content-block__item-text-info"
                  v-if="unionData.text.steps[2].content"
                  v-text="unionData.text.steps[2].content"
                ></div>
              </div>
            </div>
          </v-col>
          <v-col
            md="4"
            sm="12"
            class="px-0 py-0 steps-content-block__item steps-content-block__item--3"
          >
            <div class="steps-content-block__item-text">
              <div class="steps-content-block__item-text-number-wrapper">
                <div class="steps-content-block__item-text-number">
                  3
                </div>
              </div>
              <div class="steps-content-block__item-text-wrapper">
                <div
                  class="steps-content-block__item-text-title"
                  v-if="unionData.text.steps[3].heading"
                  v-text="unionData.text.steps[3].heading"
                ></div>
                <div
                  class="steps-content-block__item-text-info"
                  v-if="unionData.text.steps[3].content"
                  v-text="unionData.text.steps[3].content"
                ></div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="healing-block">
        <v-row>
          <v-col cols="12" class="px-0 py-0 d-flex justify-center">
            <div class="healing-block__text">
              <div class="healing-block__text-heading">
                Let the healing
                <span class="healing-block__text-heading-highlighted">
                  begin
                </span>
              </div>
              <div class="healing-block__text-button-wrapper">
                <v-btn @click="handleButtonClick" :ripple="false">
                  FILE A CLAIM
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="footer">
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="12"
            class="d-flex justify-center justify-md-start align-md-start"
          >
            <Logo :src="unionData.logo" defaultHeight="80" />
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-center justify-md-start align-md-end">
            <div class="footer-links">
              <div class="footer-link" @click="toggleInfoModal('privacy', true)">
                Policy
              </div>
              <div class="footer-link-divider"></div>
              <div class="footer-link" @click="toggleInfoModal('terms', true)">
                Terms
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="12" class="d-flex justify-center justify-md-end align-md-end">
            <div class="footer-social">
              <SvgIcon
                tag="a"
                target="_blank"
                class="footer-social-item"
                v-for="(item, i) in unionData.social"
                :href="item.src"
                :key="i"
                :icon="item.icon"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <ClaimsTermsDialog
        :styles="landingStyle"
        :title="content.PRIVACY_POLICY.title"
        :description="content.PRIVACY_POLICY.info"
        @agree="toggleInfoModal('privacy', false)"
        v-model="modals.privacy"
      />
      <ClaimsTermsDialog
        :styles="landingStyle"
        :title="content.TERMS_AND_CONDITIONS.title"
        :description="content.TERMS_AND_CONDITIONS.info"
        @agree="toggleInfoModal('terms', false)"
        v-model="modals.terms"
      />
    </v-content>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '@/utils/content';
import VideoPlayer from '@/components/VideoPlayer';
import Logo from '@/components/Logo';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import Preloader from '@/components/Preloader';

export default {
  name: 'Landing',
  components: {
    VideoPlayer,
    Preloader,
    Logo,
    ClaimsTermsDialog,
  },
  data: () => ({
    unionLoaded: false,
    union: { landingPageSettings: {} },
    modals: { privacy: false, terms: false },
    content: {
      PRIVACY_POLICY,
      TERMS_AND_CONDITIONS,
    },
  }),
  computed: {
    landingStyle() {
      return `--heroTextColor: ${this.unionData.heroTextColor};--stepsBgColor: ${this.unionData.stepsBgColor};--bgColor: ${this.unionData.bgColor};--bg-image: url(${this.unionData.bgImage});--plain-text-logo-bg: url(${this.unionData.plainTextLogoBg}); --accent-gradient: linear-gradient(227.17deg, ${this.unionData.primaryColor} 2.99%, ${this.unionData.secondaryColor} 109.59%);--primaryColor: ${this.unionData.primaryColor}; --secondaryColor: ${this.unionData.secondaryColor}`;
    },
    unionData() {
      return {
        isOlympic: this.union.olympic,
        text: {
          header: {
            heading: this.union.olympic
              ? 'Fast, easy, injury claims.'
              : 'It’s your body. Don’t let your injuries get away from you.',
            subheading: this.union.olympic ? '' : 'It only takes a few minutes to file a claim.',
          },
          steps: {
            1: {
              heading: `Fill out the form`,
              content: this.union.olympic
                ? `We’ve streamlined the process of filing an injury claim. Just fill out the online
                form and click submit. We’ll focus on taking care of the rest, while you focus on
                helping athletes recover.`
                : `Your time should be spent recovering, not doing paperwork. That’s why we’ve
                streamlined the process of filing an injury claim. Just fill out the online form and
                click submit. We’ll focus on taking care of the rest, while you focus on getting
                better.`,
            },
            2: {
              heading: `Verify the report`,
              content: this.union.olympic
                ? `After you’ve submitted the injury claim, the coach will automatically be notified
                about the incident and asked to digitally sign-off on the report, creating a
                trackable database of injury records, making sure details are never overlooked or
                forgotten.`
                : `After you’ve submitted the injury claim, your administrator will automatically be notified
                about the incident and asked to digitally sign-off on the report, creating a
                trackable database of your records making sure the details of your injury aren’t
                overlooked or forgotten.`,
            },
            3: {
              heading: `Receive claim status`,
              content: this.union.olympic
                ? `Our system analyzes the injury report for accuracy, updating relevant parties with
                an email if the claim is accepted or needs additional information.`
                : `Our system analyzes the injury report for accuracy and updates both parties with an
                email if the claim is accepted or needs additional information.`,
            },
          },
        },
        logo: (() => {
          if (this.union.landingPageSettings && this.union.landingPageSettings.logo) {
            return this.union.landingPageSettings.logo.path;
          }
          if (this.union.logo) {
            return this.union.logo.path;
          }
          return this.union.form === 'USASA'
            ? '/img/logo/usasa-logo.png'
            : '/img/logo/us-rugby.svg';
        })(),
        plainTextLogoBg: (() => {
          if (this.union.landingPageSettings && this.union.landingPageSettings.plainTextLogoBg) {
            return this.union.landingPageSettings.plainTextLogoBg.path;
          }
          return this.union.form === 'USASA' ? `/img/usasa-text.svg` : `/img/us-rugby-text.svg`;
        })(),
        bgImage: (() => {
          if (this.union.landingPageSettings && this.union.landingPageSettings.bgImage) {
            return this.union.landingPageSettings.bgImage.path;
          }
          return this.union.form === 'USASA' ? `/img/usasa-bg.png` : `/img/rugby-bg.png`;
        })(),
        primaryColor:
          (this.union.landingPageSettings && this.union.landingPageSettings.primaryColor) ||
          '#ED1F24',
        // primaryColor: this.union.form === 'USASA' ? '#ED1F24' : '#881919',
        secondaryColor:
          (this.union.landingPageSettings && this.union.landingPageSettings.secondaryColor) ||
          '#ED1F24',
        // secondaryColor: this.union.form === 'USASA' ? '#ED1F24' : '#DF4760',
        // bgColor: 'coral',
        heroTextColor:
          (this.union.landingPageSettings && this.union.landingPageSettings.heroTextColor) ||
          '#FFFFF',
        bgColor:
          (this.union.landingPageSettings && this.union.landingPageSettings.bgColor) || '#FFFFF',
        stepsBgColor:
          (this.union.landingPageSettings && this.union.landingPageSettings.stepsBgColor) ||
          '#003961',
        // videoSrc: `https://www.youtube.com/watch?v=UO6q6JAOI0Y`,
        videoSrc:
          (this.union.landingPageSettings && this.union.landingPageSettings.videoSrc) ||
          `/rugby-claims.mp4`,
        social: (this.union.landingPageSettings &&
          this.union.landingPageSettings.social &&
          this.union.landingPageSettings.social.filter(el => !!el.src)) || [
          {
            icon: 'instagram',
            src: 'https://www.instagram.com/usarugby/',
          },
          {
            icon: 'youtube',
            src: 'https://www.youtube.com/user/usarugbytv',
          },
          {
            icon: 'facebook',
            src: 'https://www.facebook.com/usarugby',
          },
          {
            icon: 'twitter',
            src: 'https://twitter.com/USARugby',
          },
        ],
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  async created() {
    this.setCustomTheme({
      theme: {
        accent: this.unionData.primaryColor,
        accentGradientFrom: this.unionData.primaryColor,
        accentGradientTo: this.unionData.secondaryColor,
      },
    });

    try {
      if (!window.LANDING_BROKER_UNION) {
        const {
          data: { sportsUnionByUrl },
        } = await this.$store.dispatch(
          'organizations/fetchSportsUnionByUrl',
          this.$route.params.union
        );
        if (!sportsUnionByUrl) {
          this.$router.push({ name: 'notFound' });
        }
        this.union = sportsUnionByUrl;
      } else {
        this.union = window.LANDING_BROKER_UNION;
      }
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'notFound' });
    }
    setTimeout(() => {
      this.unionLoaded = true;
    }, 500);
  },
  methods: {
    ...mapActions('app', ['setCustomTheme', 'resetCustomTheme']),
    toggleInfoModal(key, payload = !this.modals[key]) {
      Object.assign(this.modals, {
        [key]: payload,
      });
    },
    handleButtonClick() {
      this.$router.push({
        name: 'fnolSubmit',
        params: {
          slug: this.$route.params.union,
        },
      });
    },
  },
  beforeDestroy() {
    this.resetCustomTheme();
  },
};
</script>

<style scoped lang="scss">
@import '~@/scss/base/index';

.container::v-deep {
  @media screen and (max-width: 420px) {
    padding: 0 40px;
  }
}
.landing-page {
  background: var(--bgColor);
}
.landing {
  overflow: hidden;
}
.header {
  padding: 25px 0 24px;
  @include bp-up(md) {
    padding: 22px 0 26px;
  }
}
.heading-block__text {
  margin: 0 auto;
  max-width: 326px;
  @include bp-up(md) {
    max-width: 1110px;
  }
}
.heading-block__text-heading {
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  color: var(--heroTextColor);
  font-size: 32px;
  line-height: 35px;
  @include bp-up(md) {
    font-size: 55px;
    line-height: 65px;
  }
}
.heading-block__text-subheading {
  font-weight: bold;
  color: #ffffff;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin-top: 50px;
  color: var(--heroTextColor);
  @include bp-up(md) {
    margin-top: 40px;
    font-size: 32px;
    line-height: 25px;
  }
}
.heading-block__text-highlighted {
  color: var(--primaryColor);
}
.heading-block {
  background: var(--bg-image);
  background-size: cover;
  padding: 70px 0 121px;
  min-height: 457px;
  @include bp-up(md) {
    padding: 119px 0 54px;
  }
}
.heading-block__button-wrapper {
  margin: 30px auto 0;
  @include bp-up(md) {
    margin: 56px auto 0;
  }
  button {
    width: 266px;
  }
}
.healing-block__text-button-wrapper {
  margin: 21px auto 0;
  button {
    width: 266px;
  }
}
.video-block__player {
  max-height: 387px;
  max-width: 661px;
  width: 100%;
}
.video-block {
  padding: 25px 29px;
  @include bp-up(md) {
    padding: 82px 0 116px;
  }
}
.steps-block__heading {
  font-weight: light;
  color: var(--primaryColor);
  font-size: 33px;
  line-height: 80px;
  @include bp-up(md) {
    font-size: 65px;
    line-height: 95px;
  }
}
.steps-block__heading-highlighted {
  font-weight: bold;
}
.steps-content-block {
  position: relative;
  top: 0;
  &:before {
    content: '';
    height: 100%;
    @include bp-up(md) {
      height: calc(100% - 145px);
    }
    width: 100vw;
    background: var(--stepsBgColor);
    position: absolute;
    z-index: 0;
    left: 97px;
  }
}
.steps-content-block__item {
  margin-top: 27px;
  position: relative;
  margin-bottom: 40px;
  @include bp-up(md) {
    min-height: 588px;
    margin-bottom: 0px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 102px;
    height: 172px;
    @include bp-up(md) {
      width: 165px;
    }
    background: var(--primaryColor);
    box-shadow: 12px 4px 25px rgba(0, 0, 0, 0.15);
  }
  &--1 {
    &:before {
      @include bp-up(md) {
        height: 588px;
      }
    }
  }
  &--2 {
    &:before {
      @include bp-up(md) {
        height: 541px;
      }
    }
  }
  &--3 {
    &:before {
      @include bp-up(md) {
        height: 487px;
      }
    }
  }
}
.steps-content-block__item-text {
  z-index: 2;
  position: relative;
  // padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  @include bp-up(md) {
    padding-left: 68px;
    display: block;
  }
}
.steps-content-block__item-text-number {
  margin-left: 27px;
  text-align: center;
  margin-top: 18px;
  font-weight: bold;
  font-size: 120px;
  line-height: 95px;
  color: #ffffff;
  width: 80px;
  padding-bottom: 25px;
  border-bottom: 5px solid var(--stepsBgColor);
  @include bp-up(md) {
    padding-bottom: unset;
    margin-left: unset;
    font-size: 180px;
    line-height: 150px;
    border-bottom: none;
  }
}
.steps-content-block__item-text-wrapper {
  padding-left: 20px;
  @include bp-up(md) {
    padding-left: unset;
  }
}
.steps-content-block__item-text-title {
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  color: white;
  margin: 0px 0 11px;
  @include bp-up(md) {
    margin: 21px 0 11px;
    padding-bottom: 15px;
    border-bottom: 5px solid var(--stepsBgColor);
  }
}
.steps-content-block__item-text-info {
  max-width: 295px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
}
.healing-block__text-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 95px;
  text-align: center;
  color: var(--stepsBgColor);
  @include bp-up(md) {
    font-size: 65px;
    line-height: 95px;
  }
}
.healing-block__text-heading-highlighted {
  font-weight: 600;
}
.healing-block__text {
  justify-content: center;
  text-align: center;
}
.healing-block {
  margin: 53px auto 18px;
  padding: 12px 0 32px;
  background: var(--plain-text-logo-bg);
  background-repeat: no-repeat;
  background-position: center;
}
.footer {
  padding: 0 0 40px;
  @include bp-up(md) {
    padding: 0 0 83px;
  }
}
.footer-social {
  display: flex;
}
.footer-social-item {
  margin: 0 8px;
  border: 1px solid var(--stepsBgColor);
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  &::v-deep svg {
    g,
    path,
    polyline {
      transition: all 0.3s ease;
      fill: var(--stepsBgColor);
    }
  }
  &:hover {
    border-color: var(--primaryColor);
    &::v-deep svg {
      g,
      path,
      polyline {
        fill: var(--primaryColor);
      }
    }
  }
}
.video-block__dummy {
  width: auto;
  cursor: pointer;
  position: relative;
  img:not(.video-block__button) {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }
}
.video-block__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.footer-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}
.footer-link-divider {
  width: 1px;
  height: 12px;
  background: #979797;
  margin: 0 15px;
}
.footer-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #d8d8d8;
  &:hover {
    color: var(--stepsBgColor);
  }
}
</style>
