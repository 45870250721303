<template lang="html">
  <div class="videoComponent" :style="`--videoPrimaryColor: ${color}`" :key="src">
    <vue-plyr class="player" ref="player" :options="{ volume: 1 }" v-if="!isYoutube && !isVimeo">
      <video class="plyr-video" poster="" :src="src" height="100%" playsinline>
        <source :src="src" type="video/mp4" size="720" />
      </video>
    </vue-plyr>
    <vue-plyr v-if="isVimeo">
      <div class="plyr__video-embed">
        <iframe
          :src="`https://player.vimeo.com/video/${getVimeoId(src)}`"
          allow="autoplay; fullscreen"
        >
        </iframe>
      </div>
    </vue-plyr>
    <vue-plyr v-if="isYoutube">
      <div
        :id="`player-${getEmbedYoutubeId(src)}`"
        ref="plyr"
        class="plyr"
        data-plyr-provider="youtube"
        :data-plyr-config="options"
        :data-plyr-embed-id="getEmbedYoutubeId(src)"
      />
    </vue-plyr>
  </div>
</template>

<script>
export default {
  name: 'videoComponent',
  props: {
    color: {
      default: () => '#1DE08D',
    },
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    isYoutube() {
      return this.src.includes('youtu');
    },
    isVimeo() {
      return this.src.includes('vimeo');
    },
  },
  methods: {
    getEmbedYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    },
    getVimeoId(url) {
      return new URL(url).pathname.split('/').join('');
    },
  },
  data() {
    return {
      player: null,
      options: {
        muted: true,
      },
    };
  },
};
</script>

<style lang="scss">
.videoComponent {
  height: 100%;
  .player {
    max-height: inherit;
    .plyr-video {
      object-fit: cover;
    }
    .plyr--video {
      max-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .plyr__video-wrapper {
      width: 100%;
    }
    .plyr__control--overlaid {
      background: var(--videoPrimaryColor);
      height: 64px;
      width: 64px;
      svg {
        margin: 0 auto;
      }
      &:before {
        content: '';
        height: 72px;
        width: 72px;
        background: var(--videoPrimaryColor);
        opacity: 0.5;
        border-radius: 50%;
        left: -4px;
        position: absolute;
        top: -4px;
      }
    }
    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded='true'] {
      background: var(--videoPrimaryColor);
    }
    .plyr--full-ui input[type='range'] {
      color: var(--videoPrimaryColor);
    }
    height: 100%;
    .plyr {
      height: 100%;
      video {
        height: 100%;
      }
      .plyr__video-wrapper {
        height: 100%;
      }
    }
  }
}

.plyr__control.plyr__control--overlaid {
  background: get-color(green, gradient);
}
</style>
